import * as React from 'react'

export const ReportSpinner = (props: { title: string, subtitle: string, children?: React.ReactNode }) => {
  const { title, subtitle, children } = props

  return (
    <div className="col col-md-6 offset-md-3 text-center">
      <div><span className="fa fa-cog fa-spin fa-3x" /></div>
      <strong>{title}</strong>
      <div>{subtitle}</div>

      {children}
    </div>
  )
}
