import * as React from 'react'
import { ILocationCSVRow } from '../lib/types'

interface IProps {
  locations: ILocationCSVRow[]
  onRemoveRow({ rowNumber }: { rowNumber: number }): void
}

class HistoryReportLocationsTable extends React.Component<IProps, {}> {
  public onRemoveRow = (rowNumber: number, event: React.SyntheticEvent) => {
    const { onRemoveRow } = this.props
    event.preventDefault()
    onRemoveRow({ rowNumber })
  }

  public renderLocation(row: ILocationCSVRow) {
    const { data: location, rowNumber: id } = row

    return (
      <tr key={id}>
        <td>{location.Name}</td>
        <td>{location.Address}</td>
        <td>{location.City}</td>
        <td>{location.State}</td>
        <td>{location.Zip}</td>
        <td>{location.Latitude}</td>
        <td>{location.Longitude}</td>
        <td>
          <a
            href="#"
            data-row-number={id}
            onClick={this.onRemoveRow.bind(this, id)}
          >
            <span className="fa fa-trash-o" />
          </a>
        </td>
      </tr>
    )
  }

  public render() {
    const { locations } = this.props
    const rows = locations.map((location) => this.renderLocation(location))

    if (!locations.length) { return null }

    return (
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Address</th>
            <th>City</th>
            <th>State</th>
            <th>Zip</th>
            <th>Latitude</th>
            <th>Longitude</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    )
  }
}

export { HistoryReportLocationsTable }
