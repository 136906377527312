import * as React from 'react'
import { ILocation } from '../lib/types'

interface IProps {
  rowNumber: number,
  location: ILocation,
  submitText: string,
  onSubmit({ rowNumber, location }: { rowNumber: number, location: ILocation }): void,
}

interface IState {
  location: ILocation,
  showLatlon: boolean,
  submitted: boolean
}

class HistoryLocationForm extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    submitText: 'Save',
  }

  public constructor(props: IProps) {
    super(props)

    const { location } = props

    this.state = {
      location: { ...location },
      showLatlon: false,
      submitted: false,
    }
  }

  public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { location } = this.state
    const newLocation = { ...location }

    newLocation[event.target.name as keyof ILocation] = event.target.value

    this.setState({ location: newLocation })
  }

  public onSubmit = (event: React.SyntheticEvent) => {
    const { rowNumber, onSubmit } = this.props
    const { location } = this.state

    event.preventDefault()
    this.setState({ submitted: true })
    onSubmit({ rowNumber, location })
  }

  public toggleLatlonFields = () => {
    this.setState((prevState) => (
      {
        showLatlon: !prevState.showLatlon,
      }
    ))
  }

  public renderLatlonFields() {
    const { location } = this.state

    return (
      <div className="form-group">
        <div className="form-row">
          <div className="col col-md-6">
            <label htmlFor="history_location_latitude">Latitude</label>
            <input
              type="text"
              className="form-control"
              name="Latitude"
              id="history_location_latitude"
              onChange={this.handleChange}
              value={location.Latitude}
            />
          </div>

          <div className="col col-md-6">
            <label htmlFor="history_location_longitude">Longitude</label>
            <input
              type="text"
              className="form-control"
              name="Longitude"
              id="history_location_longitude"
              onChange={this.handleChange}
              value={location.Longitude}
            />
          </div>
        </div>

        <small className="form-text text-muted">
          Optionally, you can provide a Latitude and Longitude. Leave these blank
          if you want us to look this up for you from the address above.
        </small>
      </div>
    )
  }

  public renderShowLatlonPrompt() {
    return(
      <div className="form-group">
        <button
          onClick={this.toggleLatlonFields}
          className="btn btn-link pl-0"
        >
          I want to specify the latitude and longitude
        </button>
      </div>
    )
  }

  public render() {
    const { submitText } = this.props
    const {
      location,
      showLatlon,
      submitted,
    } = this.state

    return (
      <form onSubmit={this.onSubmit}>
        <div className="form-group">
          <label htmlFor="history_location_name">Name</label>
          <input
            type="text"
            className="form-control"
            name="Name"
            id="history_location_name"
            autoFocus={true}
            required={true}
            onChange={this.handleChange}
            value={location.Name}
          />
          <small className="form-text text-muted">
            This will be used in the generated report to identify the property.
          </small>
        </div>
        <div className="form-group">
          <label htmlFor="history_location_address">Address</label>
          <input
            type="text"
            className="form-control"
            name="Address"
            id="history_location_address"
            required={true}
            onChange={this.handleChange}
            value={location.Address}
          />
        </div>
        <div className="form-group">
          <label htmlFor="history_location_city">City</label>
          <input
            type="text"
            className="form-control"
            name="City"
            id="history_location_city"
            required={true}
            onChange={this.handleChange}
            value={location.City}
          />
        </div>
        <div className="form-group">
          <label htmlFor="history_location_state">State</label>
          <input
            type="text"
            className="form-control"
            name="State"
            id="history_location_state"
            required={true}
            onChange={this.handleChange}
            value={location.State}
          />
        </div>
        <div className="form-group">
          <label htmlFor="history_location_zip">Zip</label>
          <input
            type="text"
            className="form-control"
            name="Zip"
            id="history_location_zip"
            required={true}
            onChange={this.handleChange}
            value={location.Zip}
          />
        </div>

        {showLatlon ? this.renderLatlonFields() : this.renderShowLatlonPrompt()}

        <button type="submit" disabled={submitted} className="btn btn-primary">{submitText}</button>
      </form>
    )
  }
}

export { HistoryLocationForm }
