import gtag from 'gtag'
import * as API from '../../../lib/types/api'
import { creditPurchasePath, newCreditPurchasePath } from '../../lib/constants'
import { localFetch } from '../../lib/local_fetch'

interface IMoney {
  cents: number
  localized: string
}

const priceStringFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

class CreditPurchase {
  public static async buildNew(
    creditCurrencyKey: string, creditValue: number, couponCode?: string,
  ): Promise<CreditPurchase> {
    const url = this.buildRequestURL(newCreditPurchasePath, creditCurrencyKey, creditValue, couponCode)

    const response = await localFetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
    const json = await response.json()

    return new CreditPurchase(json)
  }

  private static buildRequestURL(
    url: string, creditCurrencyKey: string, creditValue: number, couponCode?: string,
  ): string {
    const mappedData: { [name: string]: string } = {
      'credit_purchase[currency_key]': creditCurrencyKey,
      'credit_purchase[value]': creditValue.toString(),
      'credit_purchase[coupon_code]': couponCode || '',
    }
    const queryString = Object.keys(mappedData).map((key) => (
      `${encodeURIComponent(key)}=${encodeURIComponent(mappedData[key])}`
    )).join('&')

    return `${url}?${queryString}`
  }

  public creditValue: number
  public creditCurrencyKey: string
  public creditCurrencyName: string
  public unitUSDPrice: IMoney
  public subtotalUSDPrice: IMoney
  public discountUSDPrice: IMoney
  public totalUSDPrice: IMoney
  public order: {
    id?: number,
    price?: IMoney,
    paid?: boolean,
    error?: string,
  }
  public coupon: {
    code: string,
    isValid: boolean,
    error: string | null,
  }

  constructor(api: API.ICreditPurchase) {
    this.creditValue = api.credit_value
    this.creditCurrencyKey = api.credit_currency_key
    this.creditCurrencyName = api.credit_currency_name
    this.unitUSDPrice = api.unit_usd_price
    this.subtotalUSDPrice = api.subtotal_usd_price
    this.discountUSDPrice = api.discount_usd_price
    this.totalUSDPrice = api.total_usd_price
    this.order = {
      id: api.order.id,
      price: api.order.price,
      paid: api.order.paid,
      error: api.order.error,
    }
    this.coupon = {
      code: api.coupon.code,
      isValid: api.coupon.is_valid,
      error: api.coupon.error,
    }
  }

  public async purchase() {
    const url = CreditPurchase.buildRequestURL(
      creditPurchasePath,
      this.creditCurrencyKey,
      this.creditValue,
      this.coupon.isValid ? this.coupon.code : undefined,
    )

    const response = await localFetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
    const json = await response.json()
    const purchase = new CreditPurchase(json)
    const price = purchase.order.price

    try {
      if (price) {
        gtag('event', 'purchase', {
          transaction_id: (purchase.order.id || '').toString(),
          affiliation: 'vivian',
          value: this.formatGooglePrice(price.cents / 100),
          currency: 'USD',
        })
      }
    } catch (e) {
      console.log('Unable to log purchase to GA')
      console.error(e)
    }

    return purchase
  }

  private formatGooglePrice(dollars: number): string {
    return priceStringFormatter.format(dollars)
  }
}

export { CreditPurchase }
