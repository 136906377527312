import { pluralize } from '../../lib/pluralize'
import * as React from 'react'
import { CreditPurchaseForm } from '../../UserProfile'
import { WrappedCreditCardForm } from '../../UserProfile'
import { Customer } from '../../UserProfile/lib/customer'
import { IHistoryReportWithRequiredCost } from '../lib/HistoryReport'

interface IProps {
  handleCardSaved: () => void
  handleCreditsPurchased: () => void
  customer: Customer
  report: IHistoryReportWithRequiredCost
  couponCode?: string
}

class HistoryReportCreditPurchase extends React.Component<IProps, {}> {

  public renderCreditPurchaseForm() {
    const { customer, handleCreditsPurchased, couponCode } = this.props

    return (
      <CreditPurchaseForm
        includeBalance={false}
        creditBalance={customer.creditBalances[0]}
        handlePurchased={handleCreditsPurchased}
        defaultPurchaseQuantity={this.creditsNeeded()}
        couponCode={couponCode}
      />
    )
  }

  public renderAddCardForm() {
    const { handleCardSaved } = this.props

    return(
      <div>
        <p>
          We need your credit card information in order to purchase the credits.
        </p>
        <div className="row">
          <div className="col col-md-6">
            <WrappedCreditCardForm handleCardSaved={handleCardSaved} />
          </div>
        </div>
      </div>
    )
  }

  public renderForm() {
    if (this.canPurchaseCredits()) {
      return this.renderCreditPurchaseForm()
    } else {
      return this.renderAddCardForm()
    }
  }

  public render() {
    const { report } = this.props

    return (
      <div>
        <p>
          <strong>
            This report costs <mark>{report.creditPrice.value} {pluralize('credit', report.creditPrice.value)}</mark>,
            but you only have {this.customerBalance()} {pluralize('credit', this.customerBalance())}.
          </strong>
        </p>

        {this.renderForm()}
      </div>
    )
  }

  private canPurchaseCredits(): boolean {
    const { customer } = this.props

    return !!customer && customer.hasStoredCard
  }

  private creditsNeeded(): number {
    const { report } = this.props

    return report.creditPrice.value - this.customerBalance()
  }

  private customerBalance(): number {
    const { report, customer } = this.props

    return customer.balanceFor(report.creditPrice.creditCurrencyKey)
  }
}

export { HistoryReportCreditPurchase }
