export type HailSize = 0.75 | 1.00 | 1.25 | 1.50 | 1.75 | 2.00 | 2.25 | 2.50 | 2.75 | 3.00 | 3.25 | 3.50 | 3.75 | 4.00
type Color = [number, number, number]

const COLOR_STOPS: [HailSize, Color][] = [
  [0.75, [0xff, 0xf8, 0x78]],
  [1.00, [0xff, 0xea, 0x00]],
  [1.25, [0xff, 0xc4, 0x00]],
  [1.50, [0xff, 0x9d, 0x00]],
  [1.75, [0xff, 0x7b, 0x00]],
  [2.00, [0xff, 0x4d, 0x00]],
  [2.25, [0xff, 0x26, 0x00]],
  [2.50, [0xff, 0x00, 0x00]],
  [2.75, [0xb0, 0x12, 0x00]],
  [3.00, [0x87, 0x1d, 0x00]],
  [3.25, [0x6c, 0x00, 0x75]],
  [3.50, [0xb0, 0x00, 0x90]],
  [3.75, [0xeb, 0x00, 0xd3]],
  [4.00, [0xfc, 0x63, 0xff]],
]

export function colorForSize(lookupSize: HailSize): Color {
  return (
    COLOR_STOPS.find((entry) => entry[0] == lookupSize) || [null, [0, 0, 0]]
  )[1]
}

export function colorStringForSize(lookupSize: HailSize): string {
  return `rgb(${colorForSize(lookupSize).map((color) => color.toString(10)).join(', ')})`
}

export function formatted(size: HailSize): string {
  return `${size.toFixed(2)}"`
}
