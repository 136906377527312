import * as React from 'react'

interface IProps {
  property: HAT.IProperty
}

export const PropertyTooltip: React.FunctionComponent<IProps> = ({ property }) => (
  <div>
    <div>
      <strong>{property.name} ({property.account_name})</strong>
    </div>
    <div>
      {property.group_name}
    </div>
    <div>
      {property.address}
    </div>
    <div>
      {property.city}, {property.state} {property.zip}
    </div>
    <div>
      {property.latitude}, {property.longitude}
    </div>
  </div>
)
