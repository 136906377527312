import * as ActiveStorage from 'activestorage'
import 'whatwg-fetch'

import * as HistoryReport from '../components/HistoryReport'
import setupReact from '../components/react-setup'
import * as Storm from '../components/Storm'
import * as UserProfile from '../components/UserProfile'
import * as PortalMap from '../components/PortalMap'

const ReactComponents = {
  ...HistoryReport,
  ...UserProfile,
  ...Storm,
  ...PortalMap,
}

document.addEventListener('DOMContentLoaded', () => setupReact(ReactComponents))

ActiveStorage.start()
