import * as CSV from 'papaparse'
import * as React from 'react'
import { RadioButtonGroup } from '../../common/RadioButtonGroup'
import { historyReportCreatePath } from '../../lib/constants'
import { localFetch } from '../../lib/local_fetch'
import { HistoryReport } from '../lib/HistoryReport'
import { ILocation, ILocationCSVRow } from '../lib/types'
import { HistoryLocationForm } from './HistoryLocationForm'
import { HistoryReportLocationsTable } from './HistoryReportLocationsTable'

type HistoryReportFormMode = 'single' | 'multi'
interface IProps {
  message: string
  defaultMode: HistoryReportFormMode
  hailHistoryCredits: number | 'infinite'
}

interface IState {
  locations: ILocationCSVRow[]
  CSVErrors: CSV.ParseError[]
  report?: HistoryReport
  mode: HistoryReportFormMode
}

const formModes: { name: HistoryReportFormMode, label: string }[] = [
  { name: 'single', label: 'Single Property Report' },
  { name: 'multi', label: 'Multiple Property Report' },
]

class HistoryReportForm extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    defaultMode: 'single',
  }

  constructor(props: IProps) {
    super(props)
    this.state = {
      locations: [],
      CSVErrors: [],
      mode: props.defaultMode,
    }
  }

  public buildLocation(): ILocation {
    return {
      Name: '',
      Address: '',
      City: '',
      State: '',
      Zip: '',
      Latitude: '',
      Longitude: '',
    }
  }

  public fileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    CSV.parse<ILocation>(
      (event.target.files as FileList)[0],
      {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          this.setState({
            locations: results.data.map( (data, i) =>
              ({ rowNumber: i, data }),
            ),
            CSVErrors: results.errors,
          })
        },
      },
    )
  }

  public resetForm = () => {
    this.setState({
      locations: [],
      CSVErrors: [],
    })
  }

  public removeRow = ({ rowNumber }: { rowNumber: number }) => {
    const { locations } = this.state

    this.setState({
      locations: locations.filter((item) => item.rowNumber != rowNumber),
    })
  }

  public addSingleProperty = ({ location }: { location: ILocation }) => {
    const { locations } = this.state

    this.setState({
      locations: [...locations, { rowNumber: locations.length, data: location }],
    }, () => this.saveReport())
  }

  public saveReport = async () => {
    const response = await localFetch(historyReportCreatePath, {
      method: 'POST',
      body: JSON.stringify({
        csv_locations: this.csvData(),
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
    const json = await response.json()
    const report = new HistoryReport(json)

    window.location.href = `./${report.id}`
  }

  public handleFormModeChanged = (mode: HistoryReportFormMode) => {
    this.setState({ mode })
  }

  public csvData(): string {
    const { locations } = this.state
    return CSV.unparse(locations.map((row) => row.data), { header: true })
  }

  public renderErrors() {
    const { CSVErrors } = this.state

    if (CSVErrors.length) {
      return <div>{CSVErrors}</div>
    } else {
      return ''
    }
  }

  public renderSingleForm() {
    return (
      <div>
        <h3>Enter Property Address</h3>
        <p>
          For reporting on a single property simply fill out the form below.
        </p>
        <HistoryLocationForm
          location={this.buildLocation()}
          onSubmit={this.addSingleProperty}
          submitText="Continue"
        />
      </div>
    )
  }

  public renderMultiForm() {
    return (
      <div>
        <h3>Upload a CSV of Properties</h3>
        <p>
          For reporting on multiple properties we provide a CSV upload tool.
          Simply download our CSV template, make your edits in Excel, and
          then upload the CSV file below.
        </p>
        <dl>
          <div className="row">
            <dt className="col col-12 col-sm my-3">Step 1:</dt>
            <dd className="col col-12 col-sm my-3">
              <a href="/history-report-template.csv">Download our CSV Template</a>
            </dd>
          </div>
          <div className="row">
            <dt className="col col-12 col-sm my-3">Step 2:</dt>
            <dd className="col col-12 col-sm my-3">
              Make your edits in Excel
            </dd>
          </div>
          <div className="row">
            <dt className="col col-12 col-sm my-3">Step 3:</dt>
            <dd className="col col-12 col-sm my-2">
              <form>
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="csv_file_upload"
                    onChange={this.fileSelected}
                  />
                  <label className="custom-file-label" htmlFor="csv_file_upload">Upload Edited CSV File</label>
                </div>
              </form>
            </dd>
          </div>
        </dl>
      </div>
    )
  }

  public renderForm() {
    const { hailHistoryCredits } = this.props
    const { mode } = this.state
    let renderedForm

    if (mode == 'single') {
      renderedForm = this.renderSingleForm()
    } else if (mode == 'multi') {
      renderedForm = this.renderMultiForm()
    }

    return (
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="mb-3 row">
            <div className="col col-md-8">
              <RadioButtonGroup<HistoryReportFormMode>
                prefix="history-report-form-mode"
                options={formModes}
                selected={mode}
                onChange={this.handleFormModeChanged}
              />
            </div>
            <div className="col col-md-4 text-right">
              <div>
                Credit Balance: {hailHistoryCredits === 'infinite' ? <>&infin;</> : hailHistoryCredits}
              </div>
              {hailHistoryCredits === 'infinite' ? '' : <div><a href="/portal/profile">Purchase Credits</a></div>}
            </div>
          </div>

          {this.renderErrors()}
          {renderedForm}
        </div>
      </div>
    )
  }

  public renderData() {
    const { locations } = this.state

    return (
        <div>
        <h3>Review Imported Property Data</h3>
        <p>
          We found {locations.length} properties in your upload. You can review
          them below before continuing.
        </p>
        <div className="row my-3">
          <div className="col col-md-6">
            <button onClick={this.resetForm} className="btn btn-outline-secondary">
              <span className="fa fa-chevron-left mr-2" />
              Reset and start over
            </button>
          </div>
          <div className="col col-md-6 text-right">
            <button onClick={this.saveReport} className="btn btn-primary">
              Continue <span className="fa fa-chevron-right ml-2" />
            </button>
          </div>
        </div>
        <HistoryReportLocationsTable locations={locations} onRemoveRow={this.removeRow} />
      </div>
    )
  }

  public render() {
    const { locations, mode } = this.state

    if (locations.length == 0 || mode == 'single') {
      return this.renderForm()
    } else {
      return this.renderData()
    }
  }
}

export { HistoryReportForm }
