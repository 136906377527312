import * as React from 'react'

import { CreditCard } from '../lib/credit_card'
import { WrappedCreditCardForm } from './WrappedCreditCardForm'

interface IState {
  showForm: boolean
  creditCard?: CreditCard
}

class ProfileBillingInfo extends React.Component<{}, IState> {
  public constructor(props: {}) {
    super(props)

    this.state = {
      showForm: false,
    }
  }

  public componentDidMount() {
    this.fetchCreditCard()
  }

  public toggleForm = () => {
    this.setState(({ showForm }) => ({ showForm: !showForm }))
  }

  public handleCardSaved = () => {
    window.location.reload()
  }

  public removeCard = async () => {
    const { creditCard } = this.state

    if (creditCard) {
      if (await creditCard.destroy()) {
        window.location.reload()
      }
    }
  }

  public renderForm() {
    return (
      <div className="row">
        <div className="col col-md-6">
          <WrappedCreditCardForm handleCardSaved={this.handleCardSaved} handleCancel={this.toggleForm} />
        </div>
      </div>
    )
  }

  public renderCreditCard() {
    const { creditCard } = this.state

    if (!creditCard) {
      throw new Error('Trying to render credit card data with no data!')
    }

    return (
      <div>
        <span className="fa fa-credit-card-alt mr-2" />
        {creditCard.brand} ending in {creditCard.lastFour}
        (expires {creditCard.expireMonth}/{creditCard.expireYear})
        <button onClick={this.toggleForm} className="btn btn-sm btn-link">
          Update Card
        </button>
        {' '}
        <button onClick={this.removeCard} className="btn btn-sm btn-outline-danger">
          Remove Card
        </button>
      </div>
    )
  }

  public renderAddCardLink() {
    return (
      <div>
        No card on file
        <button onClick={this.toggleForm} className="btn btn-sm btn-link">
          Add Card
        </button>
      </div>
    )
  }

  public render() {
    const { showForm, creditCard } = this.state

    if (showForm) {
      return this.renderForm()
    } else if (creditCard && creditCard.hasStoredCard) {
      return this.renderCreditCard()
    } else if (creditCard) {
      return this.renderAddCardLink()
    } else {
      return (
        <div>Loading...</div>
      )
    }
  }

  private async fetchCreditCard(newState?: Readonly<IState>) {
    const creditCard = await CreditCard.fetch()
    const updateState = newState || {}

    this.setState({ ...updateState, creditCard })
  }
}

export { ProfileBillingInfo }
