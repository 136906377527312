import * as ActionCable from 'actioncable'
import * as React from 'react'
import { connectCable } from '../../lib/cable'
import { HistoryReport as Report } from '../lib/HistoryReport'
import { ReportSpinner } from './ReportSpinner'

const MAX_WAIT = 15 * 1000

interface IProps {
  report: Report
  title: string
  subtitle: string
  handleRefreshReport?: () => {}
}

interface IState {
  cable: ActionCable.Cable
  channel?: ActionCable.Channel
  status?: IStatus
  pollingInterval?: number,
}

interface IStatus {
  message: string
  task: string
  progress: number
  taskProgress: number
  complete: boolean
  timestamp: Date
}

interface IExtStreamPayload {
  message: string
  task: string
  progress: number
  task_progress: number
  complete: boolean
}

class ReportBuildStatus extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      cable: connectCable(),
    }
  }

  public componentDidMount() {
    const { report, handleRefreshReport } = this.props
    const { cable } = this.state

    const channel = cable.subscriptions.create({
      channel: 'HistoryReportChannel',
      history_report_id: report.id,
    }, {
      connected: () => { /* no-op */ },
      disconnected: () => { /* no-op */ },
      received: this.onReceived,
    })

    this.setState(
      {
        channel,
        pollingInterval: window.setInterval(this.pollingCheck, MAX_WAIT),
      },
      () => handleRefreshReport ? handleRefreshReport() : null,
    )
  }

  public componentWillUnmount() {
    const { cable, channel, pollingInterval } = this.state

    if (channel) { channel.unsubscribe() }
    cable.disconnect()
    if (pollingInterval) { clearInterval(pollingInterval) }
  }

  public onReceived = (data: IExtStreamPayload) => {
    const { handleRefreshReport } = this.props
    const mapped = {
      message: data.message,
      task: data.task,
      progress: data.progress,
      taskProgress: data.task_progress,
      complete: data.complete,
      timestamp: new Date(),
    }

    if (handleRefreshReport && mapped.complete) {
      handleRefreshReport()
    }

    this.setState({ status: mapped })
  }

  public pollingCheck = () => {
    const { status } = this.state
    const { handleRefreshReport } = this.props
    const now = new Date()
    const statusExpireTime = new Date(now.getTime() - MAX_WAIT)

    if (!handleRefreshReport) { return }

    if (!status || status.timestamp < statusExpireTime) {
      handleRefreshReport()
    }
  }

  public renderStatus() {
    const { status } = this.state
    if (!status) { return }

    const { message, task, progress, taskProgress, complete } = status
    const taskPercent = this.displayPercent(taskProgress)
    const overallPercent = this.displayPercent(progress)

    if (complete) { return }

    return (
      <div className="mt-4">
        <div className="mb-2">
          <strong>{message}</strong>:{' '}
          {task} {taskProgress > 0 ? `(${taskPercent})` : ''}
        </div>
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: overallPercent }}
          >
            {overallPercent}
          </div>
        </div>
      </div>
    )
  }

  public render() {
    const { title, subtitle } = this.props

    return (
      <div>
        <ReportSpinner
          title={title}
          subtitle={subtitle}
        >
          {this.renderStatus()}
        </ReportSpinner>
      </div>
    )
  }

  private displayPercent(percent: number): string {
    return `${Math.round(percent * 100)}%`
  }
}

export { ReportBuildStatus }
