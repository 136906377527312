import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import HAT from 'hat'
import * as React from 'react'
import { CreditCardForm, ICreditCardFormProps } from './CreditCardForm'

const stripePromise = loadStripe(HAT.CONSTANTS.STRIPE_PUB_KEY)

class WrappedCreditCardForm extends React.Component<ICreditCardFormProps, {}> {
  public render() {
    return (
      <Elements stripe={stripePromise}>
        <CreditCardForm {...this.props} />
      </Elements>
    )
  }
}

export { WrappedCreditCardForm }
