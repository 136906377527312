import { pluralize } from '../../lib/pluralize'
import * as React from 'react'
import * as API from '../../../lib/types/api'
import { historyReportPurchasePath } from '../../lib/constants'
import { localFetch } from '../../lib/local_fetch'
import { Customer } from '../../UserProfile/lib/customer'
import { HistoryReport as Report } from '../lib/HistoryReport'
import { HistoryReportConfirmation } from './HistoryReportConfirmation'
import { HistoryReportErrors } from './HistoryReportErrors'
import { ReportBuildStatus } from './ReportBuildStatus'

interface IProps {
  report: API.IHistoryReport
  couponCode?: string
}

interface IState {
  report: Report
  customer?: Customer
}

const CompletedReport = (props: { report: Report }) => {
  const { report } = props

  return (
    <div className="col-md-6 offset-md-3">
      <h3 className="mb-3">
        Your Report is Complete
      </h3>
      <p>
        Your report with {report.locationCount} {pluralize('property', report.locationCount)} has been generated.
      </p>
      <div className="text-center mb-3">
        <span className="fa fa-5x fa-check-circle-o text-success" />
      </div>
      <a href={report.packageFileLink} className="btn btn-lg btn-block btn-primary">
        <span className="fa fa-download mr-2" />
        Download Report
      </a>
    </div>
  )
}

class HistoryReport extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      report: new Report(props.report),
    }
  }

  public componentDidMount() {
    this.refreshReport()
    this.refreshCustomer()
  }

  public refreshReport = async () => {
    const { report } = this.state
    this.setState({
      report: await Report.fetch(report.id),
    })
  }

  public runReportGenerator = async () => {
    const { report } = this.state
    const postData = {
      history_report: {
        generate_report: true,
      },
    }
    const response = await localFetch(historyReportPurchasePath(report.id), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(postData),
    })

    if (response.ok) {
      const json = await response.json()
      const newReport = new Report(json)

      this.setState({ report: newReport })
    } else {
      this.refreshReport()
      this.refreshCustomer()
    }
  }

  public render() {
    const { couponCode } = this.props
    const { report, customer } = this.state

    if (report.isValidating()) {
      return(
        <ReportBuildStatus
          report={report}
          title="Inspecting Data for Report"
          subtitle="Validating report request"
          handleRefreshReport={this.refreshReport}
        />
      )
    } else if (report.isWorking()) {
      return(
        <ReportBuildStatus
          report={report}
          title="Building Your Report"
          subtitle="Please wait while we build your report"
          handleRefreshReport={this.refreshReport}
        />
      )
    } else if (report.isFailed()) {
      return <HistoryReportErrors report={report} />
    } else if (report.waitingForConfirmation()) {
      return (
        <HistoryReportConfirmation
          handlePurchaseReport={this.runReportGenerator}
          handleRefreshCustomer={this.refreshCustomer}
          report={report.withRequiredCreditPrice()}
          customer={customer}
          couponCode={couponCode}
        />
      )
    } else {
      return <CompletedReport report={report} />
    }
  }

  private refreshCustomer = async () => {
    this.setState({
      customer: await Customer.fetch(),
    })
  }
}

export { HistoryReport }
