import * as React from 'react'
import { HistoryReport as Report, IProcessRowErrors } from '../lib/HistoryReport'

interface IProps {
  report: Report,
}

class HistoryReportErrors extends React.Component<IProps, {}> {
  public render() {
    const { report } = this.props
    const { rows: rowErrors } = report.processErrors

    const renderedErrors = Object.keys(rowErrors).map((rowNumber) => {
      const errors = rowErrors[rowNumber as keyof IProcessRowErrors]

      return (
        <div key={`row-error-${rowNumber}`}>
          <div className="alert alert-danger">
            Row #{rowNumber} {errors.join(', ')}
          </div>
        </div>
      )
    })

    return (
      <div>
        <h3>We Need Some Additional Information</h3>
        <p>
          It looks like we will need a little more information in order to build
          your report. Please see the errors below.
        </p>
        <div>
          {renderedErrors}
        </div>

        <a
          href="./new"
          className="btn btn-primary"
        >
          Upload Your Fixed Report
        </a>
      </div>
    )
  }
}

export { HistoryReportErrors }
