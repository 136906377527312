import { pluralize } from '../../../lib/pluralize'
import * as React from 'react'

import { CreditPurchase } from '../../lib/credit_purchase'
import { Customer } from '../../lib/customer'
import { CouponForm } from './CouponForm'
import { OrderDetails } from './OrderDetails'

interface IProps {
  creditBalance: Customer['creditBalances'][0]
  handlePurchase: () => void
  handleCanceled?: () => void
  includeBalance: boolean
  purchaseQuantity: number
  creditPurchase: CreditPurchase
  couponCode?: string
  handleChangeQuantity: (quantity: number) => void
  handleChangeCouponCode: (couponCode: string) => void
}

interface IState {
  showConfirmation: boolean
  showCouponForm: boolean
  purchasing: boolean
}

export class Form extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      showConfirmation: false,
      purchasing: false,
      showCouponForm: false,
    }
  }

  public handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { handleChangeQuantity } = this.props

    handleChangeQuantity(parseInt(event.target.value, 10) || 0)
  }

  public handleConfirmPurchase = async () => {
    const { handlePurchase } = this.props

    this.setState({ purchasing: true })

    await handlePurchase()

    this.setState({ purchasing: false })
  }

  public toggleConfirmation = () => {
    this.setState((prevState) => ({ showConfirmation: !prevState.showConfirmation }))
  }

  public handleCancel = () => {
    const { handleCanceled } = this.props

    if (handleCanceled) {
      handleCanceled()
    }
  }

  public toggleCouponForm = (event?: React.SyntheticEvent) => {
    if (event) { event.preventDefault() }

    this.setState((prevState) => ({ showCouponForm: !prevState.showCouponForm }))
  }

  public handleCouponCodeEntered = async (couponCode: string) => {
    const { handleChangeCouponCode } = this.props

    await handleChangeCouponCode(couponCode)

    const { creditPurchase } = this.props
    if (creditPurchase && creditPurchase.coupon.isValid) {
      this.toggleCouponForm()
    }
  }

  public renderConfirmation(creditPurchase: CreditPurchase) {
    const { purchaseQuantity } = this.props
    const { purchasing } = this.state

    return (
      <div>
        <button
          onClick={this.handleConfirmPurchase}
          className="btn btn-primary"
          disabled={purchasing}
        >
          Purchase {purchaseQuantity} {this.pluralizedCreditName(purchaseQuantity)} for{' '}
          {creditPurchase.totalUSDPrice.localized}
        </button>
        {' '}
        <button onClick={this.toggleConfirmation} className="btn btn-secondary">Back</button>

        <OrderDetails creditPurchase={creditPurchase} />
      </div>
    )
  }

  public renderContinue() {
    const { handleCanceled } = this.props

    return (
      <div>
        <button
          onClick={this.toggleConfirmation}
          className="btn btn-primary"
        >
          Continue
        </button>
        {' '}
        <button
          onClick={this.handleCancel}
          className={`btn btn-secondary ${handleCanceled ? '' : 'd-none'}`}
        >
          Cancel
        </button>
      </div>
    )
  }

  public renderBalance() {
    const { creditBalance } = this.props

    return (
      <div>
        You currently have <strong>{creditBalance.balance}</strong>{' '}
        {this.pluralizedCreditName(creditBalance.balance)}.
      </div>
    )
  }

  public renderCouponForm() {
    const { creditPurchase } = this.props

    return (
      <div className="form-group">
        <CouponForm
          creditPurchase={creditPurchase}
          handleCouponCodeEntered={this.handleCouponCodeEntered}
          handleCancel={this.toggleCouponForm}
        />
      </div>
    )
  }

  public renderCouponCode() {
    const { couponCode, creditPurchase} = this.props

    if (couponCode && creditPurchase.coupon.isValid) {
      return (
        <div>
          Using Promo Code "{couponCode}"{' '}

          <a href="#" onClick={this.toggleCouponForm}>
            Change?
          </a>
        </div>
      )
    } else {
      return (
        <a href="#" onClick={this.toggleCouponForm}>
          Have a Promo Code?
        </a>
      )
    }
  }

  public renderError() {
    const { creditPurchase } = this.props
    const { error } = creditPurchase.order
    if (!error) { return }

    return (
      <div className="invalid-feedback d-block">
        {error}
      </div>
    )
  }

  public render() {
    const { purchaseQuantity, creditPurchase, includeBalance } = this.props
    const { showCouponForm, showConfirmation } = this.state

    return (
      <div>
        {includeBalance ? this.renderBalance() : ''}

        <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="credit-balance-quantity">
              How many would you like to purchase?
            </label>
          </div>

          <div className="col-md-4">
            <input
              id="credit-balance-quantity"
              type="number"
              className="form-control"
              value={purchaseQuantity}
              onChange={this.handleQuantityChange}
              disabled={showConfirmation}
            />
            {this.renderError()}
            {this.renderCouponCode()}
          </div>

          <div className="col-md-8">
            {showConfirmation ? this.renderConfirmation(creditPurchase) : this.renderContinue()}
          </div>
        </div>

        {showCouponForm ? this.renderCouponForm() : ''}
      </div>
    )
  }

  private pluralizedCreditName(count: number): string {
    const { creditBalance } = this.props

    return pluralize(creditBalance.creditCurrencyName, count)
  }
}
