import * as React from 'react'
import { initDateFromISO, isoDateString } from '../../lib/date'
import { Map } from './Map'
import { MapNavigation } from './MapNavigation'

interface IProps {
  initialDate: string
  staticPropertyData?: string
  title?: string
}

interface IState {
  stormDate: string
  pinnedDates: string[]
}

export class MapPage extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)

    this.state = {
      stormDate: isoDateString(initDateFromISO(props.initialDate)),
      pinnedDates: [],
    }
  }

  public render() {
    const { title } = this.props
    const { stormDate, pinnedDates } = this.state

    return (
      <div className="d-flex flex-column map-fullscreen">
        <div>
          <MapNavigation
            stormDate={stormDate}
            pinnedDates={pinnedDates}
            title={title}
            onStormDateChange={this.handleDateChange}
            onStormDatePinnedToggle={this.handlePinnedDateToggle}
          />
        </div>
        <div className="h-100 position-relative">
          <Map
            stormDates={this.selectedStormDates().map(initDateFromISO)}
            propertyData={this.propertyData()}
          />
        </div>
      </div>
    )
  }

  private selectedStormDates() {
    const { stormDate, pinnedDates } = this.state

    if (this.isDatePinned(stormDate)) {
      return pinnedDates
    } else {
      return [stormDate, ...pinnedDates]
    }
  }

  private isDatePinned(checkDate: string): boolean {
    const { pinnedDates } = this.state
    return pinnedDates.includes(checkDate)
  }

  private propertyData(): string {
    const { staticPropertyData } = this.props
    const { stormDate } = this.state

    if (staticPropertyData) {
      return staticPropertyData
    } else {
      return `/admin/properties.json?impacted_on=${stormDate}`
    }
  }

  private handleDateChange = (newDate: string) => {
    this.setState({ stormDate: newDate })
  }

  private handlePinnedDateToggle = (toggleDate: string) => {
    this
      .setState((prevState) => ({ pinnedDates: this.toggleInArray(prevState.pinnedDates, toggleDate) }))
  }

  private toggleInArray<T>(ary: T[], item: T) {
    const newAry = ary.slice(0)
    const matchIndex = newAry.indexOf(item)

    if (matchIndex === -1) {
      newAry.push(item)
    } else {
      newAry.splice(matchIndex, 1)
    }

    return newAry
  }
}
