// Primative pluralize function only menat to support our minimal use-case
const pluralize = (word: string, count: number): string => {
  if (count === 1) {
    return word
  } else {
    if (word.endsWith('y')) {
      return `${word.slice(0, -1)}ies`
    } else {
      return `${word}s`
    }
  }
}

export { pluralize }
