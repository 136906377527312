import * as React from 'react'

interface IProps {
  name: string
  invalid: boolean
  initialValue: string
  initiallyShown: boolean
  inputClassName: string
  id?: string
}

interface IState {
  value: string
  shown: boolean
}

class PasswordField extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    inputClassName: 'form-control',
    initialValue: '',
    initiallyShown: false,
    invalid: false,
  }

  constructor(props: IProps) {
    super(props)

    this.state = {
      value: props.initialValue,
      shown: props.initiallyShown,
    }
  }

  public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ value: event.target.value })
  }

  public handleToggleShown = (event: React.SyntheticEvent) => {
    event.preventDefault()
    this.setState((prevState) => ({ shown: !prevState.shown }))
  }

  public render() {
    const { name, inputClassName, invalid, id } = this.props
    const { value, shown } = this.state
    const additionalClasses = invalid ? 'is-invalid' : ''

    return (
      <div className="input-group">
        <input
          id={id}
          name={name}
          className={[inputClassName, additionalClasses].join(' ')}
          type={shown ? 'text' : 'password'}
          value={value}
          onChange={this.handleChange}
        />
        <div className="input-group-append">
          <a href="#" className="btn btn-outline-secondary" onClick={this.handleToggleShown}>
            <i className={`fa ${shown ? 'fa-eye' : 'fa-eye-slash'}`} />
          </a>
        </div>
      </div>
    )
  }
}

export { PasswordField }
