import * as API from '../../../lib/types/api'
import { creditCardPath } from '../../lib/constants'
import { localFetch } from '../../lib/local_fetch'

class CreditCard {
  public static async fetch(): Promise<CreditCard> {
    const response = await localFetch(creditCardPath, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
    const json = await response.json()

    return new CreditCard(json)
  }

  public customerID: number | null
  public hasStoredCard: boolean
  public brand?: string
  public expireMonth?: number
  public expireYear?: number
  public lastFour?: string

  constructor(api: API.ICreditCard) {
    this.customerID = api.customer_id
    this.hasStoredCard = api.has_stored_card
    this.brand = api.stored_card_info.brand
    this.expireMonth = api.stored_card_info.expire_month
    this.expireYear = api.stored_card_info.expire_year
    this.lastFour = api.stored_card_info.last_four
  }

  public async destroy(): Promise<boolean> {
    const response = await localFetch(creditCardPath, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    })

    return response.ok
  }
}

export {
  CreditCard,
}
