import * as React from 'react'

import { CreditPurchase } from '../../lib/credit_purchase'

interface IProps {
  creditPurchase: CreditPurchase
}

export class OrderDetails extends React.Component<IProps, {}> {
  public render() {
    const { creditPurchase } = this.props

    if (creditPurchase.discountUSDPrice.cents === 0) { return null }

    return (
      <div>
        <small className="font-italic text-muted">
          {creditPurchase.subtotalUSDPrice.localized}{' '}
          - {creditPurchase.discountUSDPrice.localized} (promotion){' '}
          = {creditPurchase.totalUSDPrice.localized}
        </small>
      </div>
    )
  }
}
