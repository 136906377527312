import { pluralize } from '../../lib/pluralize'
import * as React from 'react'

import { initDateFromISO, isoDateString } from '../../lib/date'

interface IProps {
  stormDate: string
  pinnedDates: string[]
  title: string
  onStormDateChange: (newDate: string) => void
  onStormDatePinnedToggle: (pinDate: string) => void
}

interface IState {
}

export class MapNavigation extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    title: 'Storm Map',
  }

  public render() {
    const { title, stormDate, pinnedDates } = this.props
    const isPinned = pinnedDates.includes(stormDate)

    return (
      <div className="container">
        <div className="row my-3">
          <div className="col-md">
            <h3>
              {title}
            </h3>
          </div>
          <div className="col-md">
            <div className="form-inline d-inline-flex flex-nowrap">
              <button
                className="mx-2 btn btn-sm btn-outline-primary"
                onClick={this.traverseStormDate.bind(this, -1)}
              >
                &larr;
              </button>
              <input
                type="date"
                className="form-control"
                value={stormDate}
                max={this.maxStormDate()}
                onChange={this.handleStormDateChange}
              />
              <button
                className="mx-2 btn btn-sm btn-outline-primary"
                onClick={this.traverseStormDate.bind(this, 1)}
                disabled={stormDate == this.maxStormDate()}
              >
                &rarr;
              </button>
              <button
                className={`mx-2 btn btn-sm ${isPinned ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={this.handleStormDatePinnedToggle}
              >
                <i className="fa fa-thumb-tack" />
              </button>
            </div>
            {this.renderPinnedNotice()}
          </div>
        </div>
      </div>
    )
  }

  private renderPinnedNotice() {
    const { pinnedDates } = this.props

    if (!pinnedDates.length) { return }

    const formattedDates: string = pinnedDates
      .sort()
      .map((dateString) => (initDateFromISO(dateString)).toLocaleDateString())
      .join(', ')

    return (
      <div className="d-inline-block">
        <abbr title={formattedDates}>
          Showing {pinnedDates.length} pinned {pluralize('storm', pinnedDates.length)}
        </abbr>
      </div>
    )
  }

  private handleStormDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDateString = event.target.value
    if (!newDateString) { return }

    this.setStormDate(newDateString)
  }

  private handleStormDatePinnedToggle = () => {
    const { stormDate, onStormDatePinnedToggle } = this.props

    onStormDatePinnedToggle(stormDate)
  }

  private traverseStormDate(movement: number) {
    const { stormDate } = this.props
    const newDate = initDateFromISO(stormDate)

    newDate.setDate(newDate.getDate() + movement)
    this.setStormDate(isoDateString(newDate))
  }

  private setStormDate(stormDateString: string) {
    const { onStormDateChange } = this.props
    let newDateString = stormDateString

    if (stormDateString > this.maxStormDate()) {
      newDateString = this.maxStormDate()
    }

    onStormDateChange(newDateString)
  }

  private maxStormDate(): string {
    return this.yesterday()
  }

  private yesterday(): string {
    const date = new Date()
    date.setDate(date.getDate() - 1)
    return isoDateString(date)
  }
}
