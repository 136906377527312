import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js'
import { Stripe, StripeElements } from '@stripe/stripe-js'
import * as React from 'react'
import * as Constants from '../../lib/constants'
import { localFetch } from '../../lib/local_fetch'

interface IProps {
  stripe?: Stripe | null
  elements?: StripeElements | null
  handleCardSaved: () => void
  handleCardSaveFailed?: (error: string) => void
  handleCancel?: () => void
  saveButtonLabel?: React.ReactElement | string
}

interface IState {
  saving: boolean
  errorMessage?: string
}

const CARD_ELEMENT_STYLES = {
  base: {
    'fontSize': '18px',
    'color': '#495057',
    'fontFamily': '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,' +
      '"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    '::placeholder': {
      color: '#868e96',
    },
  },
  invalid: {
    color: '#9e2146',
  },
}

class CreditCardForm extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    saveButtonLabel: 'Save Card',
  }

  public constructor(props: IProps) {
    super(props)

    this.state = {
      saving: false,
    }
  }

  public handleSubmit = async () => {
    const {
      stripe,
      elements,
      handleCardSaved,
    } = this.props

    if (!stripe || !elements) {
      throw new Error('Stripe is not setup properly!')
    }

    const cardElement = elements.getElement(CardElement)

    if (!cardElement) {
      throw new Error('Cannot find Stripe CardElement')
    }

    this.setState({ saving: true })

    const { token } = await stripe.createToken(cardElement)

    if (!token) {
      this.setState({ saving: false })
      return
    }

    const response = await localFetch(Constants.creditCardPath, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        credit_card: { token: token.id },
      }),
    })

    if (response.ok) {
      handleCardSaved()
    } else {
      const json = await response.json()
      this.handleErrorSavingCard(json.error)
    }
  }

  public handleCancel = () => {
    const { handleCancel } = this.props

    if (handleCancel) { handleCancel() }
  }

  public render() {
    const { handleCancel, saveButtonLabel } = this.props
    const { saving, errorMessage } = this.state
    let renderedError

    if (errorMessage) {
      renderedError = (
        <div className="invalid-feedback d-block">
          {errorMessage}
        </div>
      )
    }

    return (
      <div>
        <div className="form-group">
          <CardElement
            className="form-control"
            options={{
              style: CARD_ELEMENT_STYLES,
            }}
          />
          {renderedError}
        </div>
        <button
          className="btn btn-primary"
          onClick={this.handleSubmit}
          disabled={saving}
        >
          {saveButtonLabel}
        </button>
        {' '}
        <button
          className={`btn btn-secondary ${handleCancel ? '' : 'd-none'}`}
          onClick={this.handleCancel}
        >
          Cancel
        </button>
      </div>
    )
  }

  private handleErrorSavingCard(error: string) {
    const { handleCardSaveFailed } = this.props

    this.setState({
      errorMessage: error,
      saving: false,
    })

    if (handleCardSaveFailed) {
      handleCardSaveFailed(error)
    }
  }
}

const WrappedCreditCardForm = (props: IProps) => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <CreditCardForm stripe={stripe} elements={elements} {...props} />
    )}
  </ElementsConsumer>
)

export { WrappedCreditCardForm as CreditCardForm, IProps as ICreditCardFormProps }
