import React, { useState, useCallback, useEffect } from 'react'
import { WebMercatorViewport } from '@deck.gl/core'
import { IconLayer } from '@deck.gl/layers'
import DeckGL from '@deck.gl/react'
import HAT from 'hat'
import { StaticMap } from 'react-map-gl'
import { PropertyTooltip } from '../../Storm/components/PropertyTooltip'
import { fetchJSON } from '../../lib/local_fetch'
import { getBounds } from '../../lib/get_bounds'

// The types that get loaded for this aren't right
const UntypedDeckGL = DeckGL as any

const TOOLTIP_OFFSET = 10
const MAP_STYLE = 'mapbox://styles/mapbox/streets-v11'
const INITIAL_VIEW_STATE = {
  width: '100%',
  height: '100%',
  latitude: 39.0,
  longitude: -95.0,
  zoom: 3.0,
  pitch: 0,
  bearing: 0,
}
const VIEWPORT_CALCULATION_PARAMS = {
  width: 400,
  height: 250,
}
const MAX_AUTO_ZOOM = 12
const RETRY_PROPERTY_FETCH_INTERVAL = 2000

interface IProps {
  propertyData: string
}

interface IPosition {
  x: number
  y: number
}

const Tooltip = ({
  position,
  property,
}: {
  position: IPosition
  property: HAT.IProperty | null
}) => {
  if (!property) {
    return null
  }

  const { x, y } = position

  return (
    <div
      className="map-tooltip"
      style={{ top: y + TOOLTIP_OFFSET, left: x + TOOLTIP_OFFSET }}
    >
      <PropertyTooltip property={property} />
    </div>
  )
}

const getProperties = async (url: string): Promise<HAT.IProperty[]> => {
  const result = await fetchJSON<HAT.IProperty[]>(url)
  if (result.success) {
    return result.success.filter((p) => p.latitude && p.longitude)
  } else {
    return []
  }
}

export const PortalMap: React.FunctionComponent<IProps> = ({
  propertyData,
}) => {
  const [properties, setProperties] = useState<HAT.IProperty[]>([])
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE)
  const [hoveredProperty, setHoveredProperty] = useState<null | HAT.IProperty>(
    null,
  )
  const [tooltipPosition, setTooltipPosition] = useState<IPosition>({
    x: 0,
    y: 0,
  })

  const onViewStateChange = useCallback(({ viewState: newViewState }) => {
    setViewState(newViewState)
  }, [])

  const propertyLayer = new IconLayer<HAT.IProperty>({
    id: 'properties-icons',
    data: properties,
    pickable: true,
    getIcon: () => ({
      url: '/map-icons/blue-pin.png',
      width: 90,
      height: 81,
    }),
    getSize: 90,
    getPosition: (p) => [p.longitude, p.latitude],
    onHover: ({ x, y, object }) => {
      setHoveredProperty(object)
      setTooltipPosition({ x, y })
    },
    onClick: ({ object }) => {
      window.location.href = `/portal/properties/${object.id}`
    },
  })

  const fetchAndSetProperties = async () => {
    const newProperties = await getProperties(propertyData)
    if (newProperties.length) {
      setProperties(newProperties)
    } else {
      setTimeout(fetchAndSetProperties, RETRY_PROPERTY_FETCH_INTERVAL)
    }
  }

  useEffect(() => { fetchAndSetProperties() }, [])

  // Autozoom to displayed properties once loaded
  useEffect(() => {
    if (!properties.length) {
      return
    }
    const bounds = getBounds(properties).map((point) => [
      point.longitude,
      point.latitude,
    ])
    const viewport = new WebMercatorViewport(VIEWPORT_CALCULATION_PARAMS)
    const { latitude, longitude, zoom } = viewport.fitBounds(bounds) as any
    setViewState({
      ...viewState,
      latitude,
      longitude,
      zoom: zoom > MAX_AUTO_ZOOM ? MAX_AUTO_ZOOM : zoom,
    })
  }, [properties])

  return (
    <div className="embed-responsive embed-responsive-16by9">
      <UntypedDeckGL
        layers={[propertyLayer]}
        viewState={viewState}
        onViewStateChange={onViewStateChange}
        controller={true}
        className="embed-responsive-item"
      >
        <StaticMap
          width={INITIAL_VIEW_STATE.width}
          height={INITIAL_VIEW_STATE.height}
          reuseMaps={true}
          mapStyle={MAP_STYLE}
          preventStyleDiffing={true}
          mapboxApiAccessToken={HAT.CONSTANTS.MAPBOX_PUBLIC_API_KEY}
        />
        <Tooltip position={tooltipPosition} property={hoveredProperty} />
      </UntypedDeckGL>
    </div>
  )
}
