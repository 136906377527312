import { pluralize } from '../../lib/pluralize'
import * as React from 'react'
import { Customer } from '../../UserProfile/lib/customer'
import { IHistoryReportWithRequiredCost } from '../lib/HistoryReport'
import { HistoryReportCreditPurchase } from './HistoryReportCreditPurchase'
import { HistoryReportPurchase } from './HistoryReportPurchase'

interface IProps {
  handlePurchaseReport: () => void
  handleRefreshCustomer: () => void
  report: IHistoryReportWithRequiredCost
  customer?: Customer
  couponCode?: string
}

interface IState {
  creditPurchaseRequested: boolean
}

class HistoryReportConfirmation extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)

    this.state = {
      creditPurchaseRequested: false,
    }
  }

  public renderFreePurchaseButton() {
    const { handlePurchaseReport } = this.props

    return (
      <div>
        <p>
          This is a <mark>free</mark> report.
        </p>
        <button
          onClick={handlePurchaseReport}
          className="btn btn-lg btn-primary"
        >
          Generate Report
        </button>
    </div>
    )
  }

  public renderPurchaseButton() {
    const { handlePurchaseReport, report } = this.props
    const { creditPrice } = report

    return (
      <div>
        <p>
          This report costs <mark>{creditPrice.value} {pluralize('credit', creditPrice.value)}</mark>,
          and you have {this.customerBalance()} {pluralize('credit', this.customerBalance())}.
        </p>
        <button
          onClick={handlePurchaseReport}
          className="btn btn-lg btn-primary"
        >
          Purchase Report with {creditPrice.value} {pluralize('Credit', creditPrice.value)}
        </button>
    </div>
    )
  }

  public toggleCreditPurchaseRequested = () => {
    this.setState((prevState) => ({ creditPurchaseRequested: !prevState.creditPurchaseRequested }))
  }

  public renderContent() {
    const { customer, report, couponCode, handleRefreshCustomer, handlePurchaseReport } = this.props

    if (this.canPurchaseReport()) {
      if (report.creditPrice.value === 0) {
        return this.renderFreePurchaseButton()
      } else {
        return this.renderPurchaseButton()
      }
    } else if (customer) {
      if (this.showCreditPurchase()) {
        return (
          <HistoryReportCreditPurchase
            handleCardSaved={handleRefreshCustomer}
            handleCreditsPurchased={handlePurchaseReport}
            customer={customer}
            report={report}
            couponCode={couponCode}
          />
        )
      } else {
        return (
          <HistoryReportPurchase
            handleCreditsPurchased={handlePurchaseReport}
            handleRequestCreditForm={this.toggleCreditPurchaseRequested}
            customer={customer}
            report={report}
            couponCode={couponCode}
          />
        )
      }
    } else {
      return <div>Loading...</div>
    }
  }

  public render() {
    const { report } = this.props

    return (
      <div>
        <h3 className="mb-3">
          Ready to build your report for {report.locationCount} {pluralize('property', report.locationCount)}
        </h3>

        {this.renderContent()}
      </div>
    )
  }

  private showCreditPurchase(): boolean {
    const { customer } = this.props
    const { creditPurchaseRequested } = this.state

    return creditPurchaseRequested || !!customer && this.customerBalance() > 0
  }

  private canPurchaseReport(): boolean {
    const { customer, report } = this.props
    const { creditPrice } = report

    return this.creditsNeeded() <= 0 || (
      !!customer &&
      customer.hasSufficientCredits(creditPrice.creditCurrencyKey, creditPrice.value)
    )
  }

  private creditsNeeded(): number {
    const { report } = this.props

    return report.creditPrice.value - this.customerBalance()
  }

  private customerBalance(): number {
    const { report, customer } = this.props

    return customer ? customer.balanceFor(report.creditPrice.creditCurrencyKey) : 0
  }
}

export { HistoryReportConfirmation }
