import * as API from '../../../lib/types/api'
import { customerPath } from '../../lib/constants'
import { localFetch } from '../../lib/local_fetch'

class Customer {
  public static async fetch(): Promise<Customer> {
    const response = await localFetch(customerPath, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
    const json = await response.json()

    return new Customer(json)
  }

  public id: number
  public name: string
  public hasStoredCard: boolean
  public storedCardInfo: {
    brand?: string,
    expireMonth?: number,
    expireYear?: number,
    lastFour?: string,
  }
  public creditBalances: {
    creditCurrencyKey: string,
    creditCurrencyName: string,
    balance: number,
  }[]

  constructor(api: API.ICustomer) {
    this.id = api.id
    this.name = api.name
    this.creditBalances = []

    this.hasStoredCard = api.has_stored_card
    this.storedCardInfo = {
      brand: api.stored_card_info.brand,
      expireMonth: api.stored_card_info.expire_month,
      expireYear: api.stored_card_info.expire_year,
      lastFour: api.stored_card_info.last_four,
    }

    api.credit_balances.forEach((balance) => {
      this.creditBalances.push({
        creditCurrencyKey: balance.credit_currency_key,
        creditCurrencyName: balance.credit_currency_name,
        balance: balance.balance,
      })
    })
  }

  public hasSufficientCredits(currency: API.CreditCurrencyTypes, amount: number): boolean {
    return this.balanceFor(currency) >= amount
  }

  public balanceFor(currency: API.CreditCurrencyTypes): number {
    const balance = this.creditBalances
      .find((b) => b.creditCurrencyKey == currency) || { balance: 0 }

    return balance.balance
  }
}

export { Customer }
