import * as React from 'react'

import { WrappedCreditCardForm } from '../../UserProfile'
import { CouponForm } from '../../UserProfile/components/CreditPurchaseForm/CouponForm'
import { OrderDetails } from '../../UserProfile/components/CreditPurchaseForm/OrderDetails'
import { CreditCard } from '../../UserProfile/lib/credit_card'
import { CreditPurchase } from '../../UserProfile/lib/credit_purchase'
import { Customer } from '../../UserProfile/lib/customer'
import { IHistoryReportWithRequiredCost } from '../lib/HistoryReport'

interface IProps {
  handleCreditsPurchased: () => void
  customer: Customer
  report: IHistoryReportWithRequiredCost
  handleRequestCreditForm: () => void
  couponCode?: string
}

interface IState {
  purchased: boolean
  rememberCard: boolean
  showCouponForm: boolean
  couponCode?: string
  creditPurchase?: CreditPurchase
}

interface IStateRequiredCreditPurchase extends IState {
  creditPurchase: CreditPurchase
}

class HistoryReportPurchase extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)

    this.state = {
      purchased: false,
      rememberCard: true,
      showCouponForm: false,
      couponCode: props.couponCode,
    }
  }

  public componentDidMount() {
    this.fetchCreditPurchase()
  }

  public handlePurchase = async () => {
    const { handleCreditsPurchased } = this.props
    const { creditPurchase, purchased, rememberCard } = this.requiredState()

    if (purchased) { return }

    await this.setState({ purchased: true })

    const purchase = await creditPurchase.purchase()

    if (purchase.order.paid) {
      handleCreditsPurchased()
    } else {
      this.setState({ purchased: false, creditPurchase: purchase })
    }

    if (!rememberCard) {
      this.forgetCard()
    }
  }

  public handleCouponCodeEntered = async (couponCode: string) => {
    await this.setState({ couponCode })
    await this.fetchCreditPurchase()

    const { creditPurchase } = this.state
    if (creditPurchase && creditPurchase.coupon.isValid) {
      this.toggleCouponForm()
    }
  }

  public toggleRememberCard = () => {
    this.setState((prevState) => ({ rememberCard: !prevState.rememberCard }))
  }

  public toggleCouponForm = () => {
    this.setState((prevState) => ({ showCouponForm: !prevState.showCouponForm }))
  }

  public renderPurchaseButton() {
    return (
      <button
        className="btn btn-lg btn-primary"
        onClick={this.handlePurchase}
      >
        {this.callToAction()}
      </button>
    )
  }

  public renderAction() {
    const { creditPurchase, purchased, rememberCard } = this.state
    const { customer } = this.props

    if (!creditPurchase || purchased) {
      return <div>Loading...</div>
    }

    if (customer.hasStoredCard || creditPurchase.totalUSDPrice.cents == 0) {
      return this.renderPurchaseButton()
    } else {
      return (
        <div className="row">
          <div className="col col-md-6">
            <div className="form-group">
              <div className="custom-control custom-switch">
                <input
                  id="storeCardInput"
                  type="checkbox"
                  className="custom-control-input"
                  checked={rememberCard}
                  onChange={this.toggleRememberCard}
                />
                <label className="custom-control-label" htmlFor="storeCardInput">
                  Remember this card for next time
                </label>
              </div>
            </div>

            <WrappedCreditCardForm
              handleCardSaved={this.handlePurchase}
              saveButtonLabel={this.callToAction()}
            />
          </div>
        </div>
      )
    }
  }

  public renderCouponForm() {
    const { showCouponForm, creditPurchase, purchased } = this.state

    if (!showCouponForm || !creditPurchase || purchased) { return }

    return (
      <div className="mt-3">
        <CouponForm
          creditPurchase={creditPurchase}
          handleCouponCodeEntered={this.handleCouponCodeEntered}
          handleCancel={this.toggleCouponForm}
        />
      </div>
    )
  }

  public renderOrderDetails() {
    const { creditPurchase } = this.state

    if (!creditPurchase) { return }

    return (
      <OrderDetails creditPurchase={creditPurchase} />
    )
  }

  public handleRequestCreditForm = (event: React.SyntheticEvent) => {
    const { handleRequestCreditForm } = this.props

    event.preventDefault()
    handleRequestCreditForm()
  }

  public handleForgetCardRequest = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    await this.forgetCard()
    window.location.reload()
  }

  public renderError() {
    const { creditPurchase } = this.state
    if (!creditPurchase) { return }

    const { error } = creditPurchase.order
    if (!error) { return }

    return (
      <div className="alert alert-danger">
        It looks like there was an error when trying to charge your card.
        The error returned was "{error}". You can try again, or you can{' '}
        <a
          href="#"
          onClick={this.handleForgetCardRequest}
        >
          try a different credit card
        </a>.
      </div>
    )
  }

  public render() {
    return (
      <div>
        {this.renderError()}
        {this.renderAction()}
        {this.renderOrderDetails()}

        {this.renderCouponForm()}

        <p className="mt-4">
          If you'd rather buy reports in bulk, you can{' '}
          <a
            href="#"
            onClick={this.handleRequestCreditForm}
          >
            purchase Hail History Report Credits
          </a>.
        </p>

        <p className="mt-4" style={{ display: this.showCouponLink() ? 'block' : 'none' }}>
          <a
            href="#"
            onClick={this.toggleCouponForm}
          >
            Have a Promo Code?
          </a>
        </p>
      </div>
    )
  }

  private callToAction() {
    const { creditPurchase } = this.requiredState()

    return (
      <span>
        Purchase Report for {creditPurchase.totalUSDPrice.localized}
      </span>
    )
  }

  private requiredState(): IStateRequiredCreditPurchase {
    const { creditPurchase } = this.state

    if (!creditPurchase) { throw new Error('Cannot confirm undefined creditPurchase!') }

    return { ...this.state, creditPurchase }
  }

  private showCouponLink(): boolean {
    const { creditPurchase, showCouponForm } = this.state

    return !(
      showCouponForm ||
      (!!creditPurchase && creditPurchase.coupon.isValid)
    )
  }

  private async fetchCreditPurchase() {
    const { report } = this.props
    const { couponCode } = this.state
    const creditPurchase = await CreditPurchase.buildNew(
      report.creditPrice.creditCurrencyKey,
      report.creditPrice.value,
      couponCode,
    )

    this.setState({ creditPurchase })
  }

  private async forgetCard() {
    const creditCard = await CreditCard.fetch()
    creditCard.destroy()
  }
}

export { HistoryReportPurchase }
