import * as React from 'react'
import { CreditPurchase } from '../../lib/credit_purchase'

interface IProps {
  creditPurchase: CreditPurchase
  handleCouponCodeEntered: (couponCode: string) => void
  handleCancel: () => void
}

interface IState {
  couponCode: string
}

export class CouponForm extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)

    this.state = {
      couponCode: props.creditPurchase.coupon.code,
    }
  }

  public handleCouponCodeChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCode = event.target.value

    this.setState({ couponCode: newCode })
  }

  public handleCouponCodeEntered = async () => {
    const { handleCouponCodeEntered } = this.props
    const { couponCode } = this.state

    handleCouponCodeEntered(couponCode)
  }

  public handleCancel = async () => {
    const { handleCancel } = this.props

    handleCancel()
  }

  public renderError() {
    const { creditPurchase } = this.props
    const { error, code: currentCode } = creditPurchase.coupon
    if (!error || !currentCode) { return }

    return (
      <div className="form-inline">
        <div className="invalid-feedback d-block">
          {error}
        </div>
      </div>
    )
  }

  public render() {
    const { couponCode } = this.state

    return (
      <div>
        <div className="form-inline">
          <div className="input-group">
            <input
              type="text"
              className="form-control text-monospace text-uppercase"
              placeholder="code"
              onChange={this.handleCouponCodeChange}
              value={couponCode}
            />
          </div>
          <div className="input-group">
            <button
              className="btn btn-primary mx-2"
              onClick={this.handleCouponCodeEntered}
            >
              Apply Promo Code
            </button>
            {' '}
            <button
              className={`btn btn-secondary`}
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>

        {this.renderError()}
      </div>
    )
  }
}
