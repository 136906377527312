import * as API from '../../../lib/types/api'
import { historyReportShowPath } from '../../lib/constants'
import { localFetch } from '../../lib/local_fetch'

interface IProcessRowErrors {
  [index: string]: string[]
}
interface IProcessErrors {
  rows: IProcessRowErrors
}
interface IUser {
  id: number
  email: string
  firstName: string
  lastName: string
}

interface ICreditPrice {
  value: number
  creditCurrencyKey: API.CreditCurrencyTypes
  creditCurrencyName: string
}

interface IHistoryReportWithRequiredCost extends HistoryReport {
  creditPrice: ICreditPrice
}

class HistoryReport {
  public static readonly VALIDATING_STATES = ['pending', 'validating']
  public static readonly WORKING_STATES = ['charged', 'processing']
  public static readonly WAITING_STATES = ['validated']
  public static readonly COMPLETE_STATES = ['complete']
  public static readonly FAILED_STATES = ['failed']

  public static async fetch(id: number, { withLocations = false } = {}): Promise<HistoryReport> {
    const response = await localFetch(historyReportShowPath(id, withLocations), {
      headers: {
        Accept: 'application/json',
      },
    })
    const json = await response.json()

    return new HistoryReport(json)
  }

  public id: number
  public state: API.HistoryReportStatesType
  public requestedBy: IUser
  public processErrors: IProcessErrors
  public packageFileLink: string
  public creditPrice?: ICreditPrice
  public locationCount: number
  public eventCount: number
  public createdAt: Date
  public updatedAt: Date

  constructor(api: API.IHistoryReport) {
    this.id = api.id
    this.state = api.state
    this.requestedBy = {
      id: api.requested_by.id,
      email: api.requested_by.email,
      firstName: api.requested_by.first_name,
      lastName: api.requested_by.last_name,
    }
    this.packageFileLink = api._links.package_file
    this.processErrors = this.buildProcessErrors(api.process_errors)
    if (api.credit_price) {
      this.creditPrice = {
        value: api.credit_price.value,
        creditCurrencyKey: api.credit_price.credit_currency_key,
        creditCurrencyName: api.credit_price.credit_currency_name,
      }
    }
    this.locationCount = api.location_count
    this.eventCount = api.event_count
    this.createdAt = new Date(api.created_at)
    this.updatedAt = new Date(api.updated_at)
  }

  public withRequiredCreditPrice(): IHistoryReportWithRequiredCost {
    if (this.creditPrice != null) {
      return this as IHistoryReportWithRequiredCost
    } else {
      throw new Error('HistoryReport has null credit fields!')
    }
  }

  public isValidating(): boolean {
    return HistoryReport.VALIDATING_STATES.indexOf(this.state) >= 0
  }

  public waitingForConfirmation(): boolean {
    return this.stateMatches(HistoryReport.WAITING_STATES)
  }

  public isFailed(): boolean {
    return this.stateMatches(HistoryReport.FAILED_STATES)
  }

  public isWorking(): boolean {
    return this.stateMatches(HistoryReport.WORKING_STATES)
  }

  public hasErrors(): boolean {
    return Object.keys(this.processErrors.rows).length > 0
  }

  private buildProcessErrors(errors: API.IHistoryReportProcessErrors): IProcessErrors {
    return { rows: errors.rows || {} }
  }

  private stateMatches(states: string[]) {
    return states.indexOf(this.state) >= 0
  }
}

export { HistoryReport, IProcessRowErrors, IHistoryReportWithRequiredCost }
