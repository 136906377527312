import * as ActionCable from 'actioncable'

const DEFAULT_WS_URL = '/cable'

export function connectCable(): ActionCable.Cable {
  const metaTag = document.querySelector('meta[name="action-cable-url"]')
  let url: string | null = null

  if (metaTag) {
    url = metaTag.getAttribute('content')
  }

  return ActionCable.createConsumer(url || DEFAULT_WS_URL)
}
