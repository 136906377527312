import * as React from 'react'

import { CreditPurchase } from '../../lib/credit_purchase'
import { Customer } from '../../lib/customer'
import { Form } from './Form'

interface IProps {
  creditBalance: Customer['creditBalances'][0]
  handlePurchased: () => void
  handleCanceled?: () => void
  defaultPurchaseQuantity: number
  includeBalance: boolean
  couponCode?: string
}

interface IState {
  previousDefaultPurchaseQuantity: number
  purchaseQuantity: number
  creditPurchase?: CreditPurchase
  couponCode?: string
}

export class DataManager extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    defaultPurchaseQuantity: 5,
    includeBalance: true,
  }

  public static getDerivedStateFromProps(nextProps: Readonly<IProps>, prevState: IState): Partial<IState> | null {
    if (prevState.previousDefaultPurchaseQuantity !== nextProps.defaultPurchaseQuantity) {
      return {
        previousDefaultPurchaseQuantity: nextProps.defaultPurchaseQuantity,
        purchaseQuantity: nextProps.defaultPurchaseQuantity,
      }
    } else {
      return null
    }
  }

  constructor(props: IProps) {
    super(props)

    this.state = {
      previousDefaultPurchaseQuantity: props.defaultPurchaseQuantity,
      purchaseQuantity: props.defaultPurchaseQuantity,
      couponCode: props.couponCode,
    }
  }

  public componentDidMount() {
    this.fetchCreditPurchaseData()
  }

  public handleChangeQuantity = async (purchaseQuantity: number) => {
    await this.setState({ purchaseQuantity })
    await this.fetchCreditPurchaseData()
  }

  public handleChangeCouponCode = async (couponCode: string) => {
    await this.setState({ couponCode })
    await this.fetchCreditPurchaseData()
  }

  public fetchCreditPurchaseData = async () => {
    const { creditBalance } = this.props
    const { purchaseQuantity, couponCode } = this.state
    const creditPurchase = await CreditPurchase.buildNew(
      creditBalance.creditCurrencyKey,
      purchaseQuantity,
      couponCode,
    )

    this.setState({ creditPurchase })
  }

  public handlePurchase = async () => {
    const { handlePurchased } = this.props
    const { creditPurchase } = this.state

    if (!creditPurchase) { throw new Error('Cannot confirm undefined creditPurchase!') }

    const purchase = await creditPurchase.purchase()

    if (purchase.order.paid) {
      handlePurchased()
    }
  }

  public handleCancelConfirmPurchase = () => {
    this.setState({ creditPurchase: undefined })
  }

  public handleCancel = () => {
    const { handleCanceled } = this.props

    if (handleCanceled) {
      handleCanceled()
    }
  }

  public render() {
    const {
      creditBalance,
      handleCanceled,
      includeBalance,
    } = this.props
    const {
      purchaseQuantity,
      couponCode,
      creditPurchase,
    } = this.state

    if (!creditPurchase) { return 'Loading...' }

    return (
      <Form
        creditBalance={creditBalance}
        includeBalance={includeBalance}
        purchaseQuantity={purchaseQuantity}
        creditPurchase={creditPurchase}
        couponCode={couponCode}
        handlePurchase={this.handlePurchase}
        handleCanceled={handleCanceled}
        handleChangeQuantity={this.handleChangeQuantity}
        handleChangeCouponCode={this.handleChangeCouponCode}
      />
    )
  }
}
