import { RGBAColor } from '@deck.gl/core'
import { GeoJsonLayer, ScatterplotLayer } from '@deck.gl/layers'
import DeckGL from '@deck.gl/react'
import HAT from 'hat'
import * as React from 'react'
import { PropertyTooltip } from './PropertyTooltip'
import { StaticMap } from 'react-map-gl'
import * as HailSize from '../../../lib/hail_size'
import * as Constants from '../../lib/constants'
import { isoDateString } from '../../lib/date'

// The types that get loaded for this aren't right
const UntypedDeckGL = DeckGL as any

const TOOLTIP_OFFSET = 10
const MAP_STYLE = 'mapbox://styles/mapbox/light-v9'
const PROPERTY_BUBBLE_COLOR: RGBAColor = [0x62, 0xb0, 0x31]
const INITIAL_VIEW_STATE = {
  width: '100%',
  height: '100%',
  latitude: 39.58,
  longitude: -97.89,
  zoom: 4.6,
}

interface IViewState {
  width: string
  height: string
  latitude: number
  longitude: number
  zoom: number
}

interface IProps {
  stormDates: Date[]
  initialViewState: IViewState
  propertyData?: string
}

interface IState {
  x?: number
  y?: number
  hoveredProperty?: HAT.IProperty
  hoveredSwath?: IHoveredSwath
}

interface IHoveredSwath {
  date: Date
  properties: { value: HailSize.HailSize }
}

interface IGeoJSONFeature {
  properties: { value: HailSize.HailSize }
}

export class Map extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    initialViewState: INITIAL_VIEW_STATE,
  }

  public constructor(props: IProps) {
    super(props)

    this.state = {
    }
  }

  public render() {
    const { initialViewState } = this.props

    return (
      <UntypedDeckGL
        layers={this.renderLayers()}
        initialViewState={initialViewState}
        controller={true}
      >
        <StaticMap
          width={initialViewState.width}
          height={initialViewState.height}
          reuseMaps={true}
          mapStyle={MAP_STYLE}
          preventStyleDiffing={true}
          mapboxApiAccessToken={HAT.CONSTANTS.MAPBOX_PUBLIC_API_KEY}
        />

        {this.renderTooltip}
      </UntypedDeckGL>
    )
  }

  private renderTooltip = () => {
    const { x, y } = this.state

    if (!this.showTooltip() || !x || !y) { return }

    return (
      <div className="map-tooltip" style={{ top: y + TOOLTIP_OFFSET, left: x + TOOLTIP_OFFSET }}>
        {this.renderPropertyTooltipInfo()}
        {this.renderSwathTooltipInfo()}
      </div>
    )
  }

  private renderPropertyTooltipInfo() {
    const { hoveredProperty } = this.state

    if (!hoveredProperty) { return }

    return <PropertyTooltip property={hoveredProperty} />
  }

  private renderSwathTooltipInfo() {
    const { hoveredSwath } = this.state

    if (!hoveredSwath) { return }
    const hailSize = hoveredSwath.properties.value

    const swatchStyles = {
      width: '1.25em',
      height: '1.25em',
      backgroundColor: HailSize.colorStringForSize(hailSize),
    }

    return (
      <div>
        <div className="d-flex">
          <div className="rounded mr-1" style={swatchStyles} />

          <strong>
            Hail Size: {HailSize.formatted(hailSize)}
          </strong>
        </div>
        <div>{hoveredSwath.date.toLocaleDateString()}</div>
      </div>
    )
  }

  private handleHoverProperty = ({ x, y, object }: {x: number, y: number, object?: HAT.IProperty }) => {
    this.setState({ x, y, hoveredProperty: object })
  }

  private handleHoverSwath = (date: Date, { x, y, object }: {x: number, y: number, object?: any }) => {
    this.setState({ x, y, hoveredSwath: object ? { ...object, date } : undefined })
  }

  private renderLayers() {
    const { stormDates } = this.props

    return [
      ...stormDates.map((date) => this.stormLayer(date)),
      this.pointLayer(),
    ].filter((e) => e !== null)
  }

  private showTooltip(): boolean {
    const { hoveredSwath, hoveredProperty } = this.state

    return !!(hoveredSwath || hoveredProperty)
  }

  private pointLayer() {
    let { propertyData } = this.props

    if (!propertyData) {
      propertyData = '{}'
    }

    return new ScatterplotLayer<HAT.IProperty>({
      id: 'properties',
      data: propertyData,
      pickable: true,
      opacity: 0.8,
      stroked: false,
      filled: true,
      radiusMinPixels: 4,
      getPosition: (p) => [p.longitude, p.latitude],
      getRadius: 4,
      getFillColor: PROPERTY_BUBBLE_COLOR,
      onHover: this.handleHoverProperty,
    })
  }

  private stormLayer(stormDate: Date) {
    return new GeoJsonLayer<IGeoJSONFeature>({
      id: `swath-storm-${isoDateString(stormDate)}`,
      data: Constants.stormSwathPath(stormDate),
      opacity: 0.6,
      stroked: false,
      filled: true,
      wireframe: false,
      getFillColor: (f) => HailSize.colorForSize(f.properties.value),
      pickable: true,
      onHover: this.handleHoverSwath.bind(this, stormDate),
    })
  }
}
