import * as React from 'react'

import { Customer } from '../lib/customer'
import { CreditPurchaseForm } from './CreditPurchaseForm'

interface IProps {
  couponCode?: string
}

interface IState {
  customer?: Customer
  showPurchaseCreditForm?: Customer['creditBalances'][0]
}

class CreditBalances extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)

    this.state = {}
  }
  public componentDidMount() {
    this.fetchCustomer()
  }

  public handleCanceled = () => {
    this.clearForm()
  }

  public handlePurchased = async () => {
    this.clearForm()
    this.fetchCustomer()
  }

  public renderCredits() {
    const { customer } = this.state

    if (!customer) {
      return <div />
    }

    return customer.creditBalances.map((balance) => (
      <div key={balance.creditCurrencyKey}>
        <strong>{balance.creditCurrencyName}:</strong> {balance.balance}
        {customer.hasStoredCard ? (
          <button
            onClick={this.handlePurchaseCredits.bind(this, balance)}
            className="btn btn-link"
          >
            Purchase More Credits
          </button>
        ) : (
          <div>Please add your billing information below to purchase credits.</div>
        )}
      </div>
    ))
  }

  public render() {
    const { couponCode } = this.props
    const { customer, showPurchaseCreditForm } = this.state

    if (showPurchaseCreditForm) {
      return (
        <CreditPurchaseForm
          handlePurchased={this.handlePurchased}
          handleCanceled={this.handleCanceled}
          creditBalance={showPurchaseCreditForm}
          couponCode={couponCode}
        />
      )
    } else if (customer) {
      return this.renderCredits()
    } else {
      return <div>Loading...</div>
    }
  }

  private handlePurchaseCredits(balance: Customer['creditBalances'][0]) {
    this.setState({
      showPurchaseCreditForm: balance,
    })
  }

  private async fetchCustomer() {
    const customer = await Customer.fetch()

    this.setState({ customer })
  }

  private clearForm() {
    this.setState({
      showPurchaseCreditForm: undefined,
    })
  }
}

export { CreditBalances }
